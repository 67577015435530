import React from 'react'
import {InputBase, Paper, Box, IconButton, Input} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear';

const utilizeFocus = () => {
	const ref = React.createRef()
	const setFocus = () => {ref.current &&  ref.current.focus()}

	return {setFocus, ref} 
}

class FilterBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleFilterTeamChange = this.handleFilterTeamChange.bind(this);
    this.handleFilterGroupChange = this.handleFilterGroupChange.bind(this);
    this.handleFilterSearchChange = this.handleFilterSearchChange.bind(this);
    this.handleFilterSearchClear = this.handleFilterSearchClear.bind(this);
    this.inputFocus = utilizeFocus()
  }

  componentDidMount() {
    this.inputFocus.setFocus()
  }

  handleFilterTeamChange(e) {
    this.props.onTeamCheckboxChange(e.target.checked)
  }

  handleFilterGroupChange(e) {
    this.props.onGroupCheckboxChange(e.target.checked)
  }

  handleFilterSearchChange(e) {
    this.props.onSearchFieldChange(e.target.value)
  }

  handleFilterSearchClear(e) {
    this.props.onFilterSearchClear()
    this.inputFocus.setFocus()
  }

  render() {
    return (
    <Box paddingTop={1} paddingBottom={1}>
    <Paper className="searchBorder" elevation={1}>
      <InputBase
        inputRef={this.inputFocus.ref}
        autoFocus
        id="search"
        variant="outlined"
        color="secondary"
        value={this.props.filterText}
        onChange={this.handleFilterSearchChange}
        className="searchInput"
        inputProps={{ 'aria-label': 'Search' }}
      >
      <Input ref={this.inputFocus.ref}></Input>  
      </InputBase>
      {this.props.filterText !== null && this.props.filterText.length > 0 &&
      <IconButton onClick={this.handleFilterSearchClear} aria-label="clear search">
        <ClearIcon color="primary" fontSize="large"></ClearIcon>
      </IconButton>
      }
      <SearchIcon color="secondary" fontSize="large"></SearchIcon>
    </Paper>
    </Box>
    );
  }
}

export default FilterBar
