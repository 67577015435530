import React from 'react';
import './App.css';
import { Box, Container } from '@mui/material';
import FilteredTable from './components/FilteredTable'
import Header from './components/Header'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const SwissReTheme = createTheme({
  palette: {
    primary: {
      main: "#627D77"
    },
    secondary: {
      main: "#819792"
    }
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingTop: 20
        }
      }
    }
  }
});

function App() {
   return (
     <ThemeProvider theme={SwissReTheme}>
      <Box paddingTop={0}> 
              <Header></Header>
              <Container maxWidth="md">
                <FilteredTable teamCheckBox={true} groupCheckBox={false}></FilteredTable>
            </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
