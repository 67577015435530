import React from 'react'
import { Paper, Box, List, ListItemText, ListItemSecondaryAction, Tooltip, ListItem, Switch, CircularProgress, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material'
import { authProvider } from '../services/authProvider';
import { GETTER_API_ROOT, SETTER_API_ROOT, GETTER_CLAIM, SETTER_CLAIM } from '../config/config'
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import LockIcon from '@mui/icons-material/Lock';

class FilteredList extends React.Component {
    state = {
        containers: null,
        dialogOpen: false,
        errorDialogOpen: false,
        dialogId: null,
        title: "",
        subtitle: "",
        description: ""
    }

    request = async (url, scope = GETTER_CLAIM, payload) => {
        const token = await authProvider.getAccessToken({scopes: [scope]});

        if (payload) {
            return fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });
        } else {
            return fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                    'Content-Type': 'application/json',
                },
            });
        }
    };

    getGroupMemberDetails() {
        const containerIds = this.state.containers.map((container) => container.id)

        const containerSearch = {
            groupIds: containerIds
        }

        this.request(GETTER_API_ROOT + '/api/GetUsersCount', GETTER_CLAIM, containerSearch)
            .then(res => res.json())
            .then((data) => {
                const newState = this.state.containers.map(container => {
                    var index = data.findIndex(item => item.id === container.id)

                    return Object.assign({}, container, { membersCount: data[index].membersCount, guestsCount: data[index].guestsCount })
                })

                this.setState({
                    containers: newState
                })
            })
    }

    getGroupSettings() {
        const containerIds = this.state.containers.map((container) => container.id)

        const containerSearch = {
            groupIds: containerIds
        }

        this.request(GETTER_API_ROOT + '/api/GetSettings', GETTER_CLAIM, containerSearch)
            .then(res => res.json())
            .then((data) => {
                const newState = this.state.containers.map(container => {
                    var index = data.findIndex(item => item.id === container.id)

                    return Object.assign({}, container, { allowGuests: data[index].allowGuests.toLowerCase() === "true" })
                })

                this.setState({
                    containers: newState
                }, this.getGroupMemberDetails)
            })
            .catch(console.log)
    }

    getMyGroups() {
        // Retrieve the initial group arra and store.
        this.request(GETTER_API_ROOT + '/api/GetMyGroups?includeSettings=false&debug=false', GETTER_CLAIM)
            .then(res => res.json())
            .then((data) => {
                const sorted = data.sort((a, b) => {return a.displayName.localeCompare(b.displayName)})
                this.setState({ containers: sorted },
                    () => {
                        this.getGroupSettings()
                    })

            })
            .catch(console.log)
    }

    allowToAddGuests(id) {
        const index = this.state.containers.findIndex((value) => value.id === id);
        if (index > -1) {
            const newState = !this.state.containers[index].allowGuests
            const payload = {status: "ok"}
            const changeIt = { allowGuests: null }
            const keepIt = { allowGuests: !newState }
            const toggleIt = { allowGuests: newState }

            this.setState({
                containers: [
                    ...this.state.containers.slice(0, index),
                    Object.assign({}, this.state.containers[index], changeIt),
                    ...this.state.containers.slice(index + 1)
                ]
            }, () => {
                this.request(SETTER_API_ROOT + "/api/SetAllowToAddGuests?guid=" + id + "&allowToAddGuests=" + newState, SETTER_CLAIM, payload)
                .then((res) => {
                    if (res.status === 200 || res.status === 204) {
                        this.setState({
                            containers: [
                                ...this.state.containers.slice(0, index),
                                Object.assign({}, this.state.containers[index], toggleIt),
                                ...this.state.containers.slice(index + 1)
                            ]
                        })
                    } else {
                        this.setState({
                            errorDialogOpen: true,
                            containers: [
                                ...this.state.containers.slice(0, index),
                                Object.assign({}, this.state.containers[index], keepIt),
                                ...this.state.containers.slice(index + 1)
                            ]
                        })
                    }
                })
                .catch((res) => {
                    this.setState({
                        errorDialogOpen: true,
                        containers: [
                            ...this.state.containers.slice(0, index),
                            Object.assign({}, this.state.containers[index], keepIt),
                            ...this.state.containers.slice(index + 1)
                        ]
                    })
                })
            })
        }
    }

    componentDidMount() {
        this.getMyGroups()
    }

    toggleChecked(id) {
        // Change status in the API
        this.allowToAddGuests(id)
    }

    openDialog(id, enabled, name) {
        if (enabled) {
            this.setState({
                dialogOpen: true,
                dialogId: id,
                title: "Disable external access for",
                subtitle: name,
                description: "Are you sure? By disabling external access, external guests can no-longer be added to this Team.  All  existing external guests will be removed automatically and will no-longer have access content in this Team."
            });
        } else {
            this.setState({
                dialogOpen: true,
                dialogId: id,
                title: "Enable external access for",
                subtitle: name,
                description: "Are you sure? By enabling external access, you can add guest users to this Team and they will then be able to access the content of this Team."
            });
        }
        
    }

    handleClose(ok) {
        if (ok) {
            this.toggleChecked(this.state.dialogId)
        }
        this.setState({dialogOpen: false});
    }

    handleErrorDialogClose() {
        this.setState({errorDialogOpen: false});
    }

    render() {
        const filterTeam = this.props.filterTeam;
        const filterGroup = this.props.filterGroup;
        const filterText = this.props.filterText.toLowerCase();

        var filteredList = this.state.containers;

        if (!filteredList) {
            return (
                <Grid container spacing={3} alignItems="center" alignContent="center" justify="space-around">
                    <Grid item xs={12}  >
                        <CircularProgress />
                    </Grid>
                </Grid>
            );
        }

        filteredList = filteredList.filter(container => (
            (container.resourceType === null
                || ((filterTeam ? true : !(container.resourceType.findIndex(resource => resource === 'Team') > -1))
                    && (filterGroup ? true: !(container.resourceType.findIndex(resource => resource === 'Team') === -1))))
            && (filterText.length === 0 || container.displayName.toLowerCase().indexOf(filterText) >= 0 || (container.description != null && container.description.toLowerCase().indexOf(filterText) >= 0))
        ))

        return (
            <div>
                    <Paper>
                        <Box padding={1}>
                            <List>
                                {filteredList.map((container) => (
                                    <ListItem key={container.id}>
                                          <ListItemText id={container.id}
                                            primary={<div>{container.displayName} {container.visibility === "Private" && <Tooltip title="Private Team"><LockIcon aria-label="Private Channel" fontSize="small" color="primary"></LockIcon></Tooltip>}</div>}
                                            secondary={container.membersCount || container.guestsCount ? container.description + ", members: " + container.membersCount + ", guests: " + container.guestsCount : container.description}
                                        />
                                        <ListItemSecondaryAction>
                                            {container.allowGuests === null &&
                                            <CircularProgress />
                                            }
                                            {container.allowGuests !== null &&
                                                <Tooltip title="Permit External">
                                                    <Switch
                                                        checked={container.allowGuests}
                                                        onChange={() => this.openDialog(container.id, container.allowGuests, container.displayName)}
                                                        edge="end"
                                                        inputProps={{ 'aria-labelledby': container.id}} />
                                                </Tooltip>
                                            }
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                            <Dialog open={this.state.dialogOpen}>
                                <DialogTitle>{this.state.title} '{this.state.subtitle}'</DialogTitle>
                                <DialogContent>{this.state.description}</DialogContent>
                                <DialogActions>
                                <Button onClick={() => this.handleClose(true)} color="primary" autoFocus>
                                    Continue
                                </Button>
                                <Button onClick={() => this.handleClose(false)} color="primary">
                                    Cancel
                                </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={this.state.errorDialogOpen}>
                                <DialogTitle><IconButton disabled={true}><WarningOutlinedIcon fontSize="large" color="error"/></IconButton>We've encountered an issue</DialogTitle>
                                <DialogContent>
                                <p>There was a problem with updating the sharing status of your Team.</p>
                                <p>If you continue to have an issue, please raise a ticket with ContactOne for support.</p>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={() => this.handleErrorDialogClose()} color="primary" autoFocus>
                                    OK
                                </Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    </Paper>
            </div>
        );
    }
}

export default FilteredList