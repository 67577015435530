import React from 'react'
import {AppBar, Toolbar} from '@mui/material'


class Header extends React.Component {
    render() {
      return(
         <AppBar position="static" className="titleBar">
             <Toolbar>
                 <img src="/SR_Logo_White.png" alt="Swiss Re Logo" className="logo" />
             </Toolbar>
         </AppBar>
    );
      }
  }

  export default Header