import React from 'react'
import {Grid, Paper} from '@mui/material'
import FilterBar from './FilterBar'
import FilteredList from './FilteredList'

class FilteredTable extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        typeTeam: this.props.teamCheckBox,
        typeGroup: this.props.groupCheckBox,
        searchFilter: ''
      }
  
      this.handleFilterTeamChange = this.handleFilterTeamChange.bind(this);
      this.handleFilterGroupChange = this.handleFilterGroupChange.bind(this);
      this.handleFilterSearchChange = this.handleFilterSearchChange.bind(this);
      this.handleFilterSearchClear = this.handleFilterSearchClear.bind(this);
    }
  
    handleFilterTeamChange(teamSelected) {
      this.setState({
        typeTeam: teamSelected
      });
    }
  
    handleFilterGroupChange(groupSelected) {
      this.setState({
        typeGroup: groupSelected
      });
    }
    handleFilterSearchChange(searchFilter) {
      this.setState({
        searchFilter: searchFilter
      });
    }

    handleFilterSearchClear() {
      this.setState({
        searchFilter: ''
      })
    }
  
    render() {
      return(
          <Grid className="gridContainer" direction="column" justify="space-around" container spacing={3}>
            <Paper elevation={0}>
              <h2>Enable your team on Microsoft Teams for external collaboration</h2>
              <p>Use this page to enable your team(s) on Microsoft Teams to allow collaboration with your external colleagues and partners.<br/>
              All of the Microsoft Teams you’re part of are listed below.</p>
              <p>You can also use the search box to find a specific Team.</p>
              <p>Use the slider to turn access for externals on or off.</p>
            </Paper>
            <FilterBar
              filterTeam={this.state.typeTeam}
              filterGroup={this.state.typeGroup}
              filterText={this.state.searchFilter}
              onGroupCheckboxChange={this.handleFilterGroupChange}
              onTeamCheckboxChange={this.handleFilterTeamChange}
              onSearchFieldChange={this.handleFilterSearchChange}
              onFilterSearchClear={this.handleFilterSearchClear}>
            </FilterBar>
            <FilteredList
              filterTeam={this.state.typeTeam}
              filterGroup={this.state.typeGroup}
              filterText={this.state.searchFilter}>
            </FilteredList>
          </Grid>
    );
      }
  }

  export default FilteredTable