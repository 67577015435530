// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { APP_CLIENT_ID, REDIRECT_URI, GETTER_CLAIM, SETTER_CLAIM, TENANT_ID } from '../config/config'
 
// Msal Configurations
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/' + TENANT_ID,
    clientId: APP_CLIENT_ID,
    redirectUri: REDIRECT_URI
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};
 
// Authentication Parameters
const authenticationParameters = {
  scopes: [GETTER_CLAIM, SETTER_CLAIM]
}
 
// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/auth.html'
}
 
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)