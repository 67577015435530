const local = {
    TENANT_ID: "e9960da4-0fbd-4d7a-943a-ba54afb16088",
    APP_CLIENT_ID: "e1c789d7-4689-45bc-be20-f8edcbe4d0f9",
    REDIRECT_URI: "http://localhost:3000",
    GETTER_CLAIM: "https://o365mgmtapiexternalsimpersonated.azurewebsites.net/user_impersonation",
    SETTER_CLAIM: "https://sr-aad-dev-eu-w-as-o365externalcollab.azurewebsites.net/user_impersonation",
    GETTER_API_ROOT: 'https://o365mgmtapiexternalsimpersonated.azurewebsites.net',
    SETTER_API_ROOT: 'https://sr-aad-dev-eu-w-as-o365externalcollab.azurewebsites.net/'
}

const dev = Object.assign({}, local, {
    REDIRECT_URI: 'https://myexternalshares.azurewebsites.net'
})

const devCustom = Object.assign({}, local, {
    REDIRECT_URI: 'https://myexternalshares.swissredev.com'
})

const prod = {
    REDIRECT_URI: 'https://o365externalcollaborationui.azurewebsites.net',
    TENANT_ID: '45597f60-6e37-4be7-acfb-4c9e23b261ea',
    APP_CLIENT_ID: '6e1ec4b1-21cc-493e-b355-ad4512fe73e9',
    GETTER_CLAIM: "https://o365externalcollaboration.azurewebsites.net/user_impersonation",
    SETTER_CLAIM: "https://sr-aad-prd-eu-w-as-o365extcoll.azurewebsites.net/user_impersonation",
    GETTER_API_ROOT: "https://o365mgmtapiexternals-delegated.azurewebsites.net",
    SETTER_API_ROOT: "https://sr-aad-prd-eu-w-as-o365extcoll.azurewebsites.net"
}

const prodCustom = Object.assign({}, prod, {
    REDIRECT_URI: 'https://myexternalshares.swissre.com'
})

// Temporary until the APIs are made available.
const nonprod = {
    TENANT_ID: "a15349bd-3c0c-4ec0-abf8-6b59a5fb60bc",
    APP_CLIENT_ID: "66346bef-40bd-4d60-8bd3-024e79c61814",
    REDIRECT_URI: "https://o365externalcollaborationuinp.azurewebsites.net",
    GETTER_CLAIM: "https://o365mgmtapiexternals-delegated-nonprod.azurewebsites.net/user_impersonation",
    SETTER_CLAIM: "https://sr-aad-prp-eu-w-as-o365extcoll.azurewebsites.net/user_impersonation",
    GETTER_API_ROOT: 'https://o365mgmtapiexternals-delegated-nonprod.azurewebsites.net',
    SETTER_API_ROOT: 'https://sr-aad-prp-eu-w-as-o365extcoll.azurewebsites.net'
}

const nonprodCustom = Object.assign({}, nonprod, {
    REDIRECT_URI: 'https://myexternalshares.swissrenonprod.com'
})

const nonprodProdTenant = {
    TENANT_ID: "45597f60-6e37-4be7-acfb-4c9e23b261ea",
    APP_CLIENT_ID: "6e1ec4b1-21cc-493e-b355-ad4512fe73e9",
    REDIRECT_URI: "https://myexternalshares-nonprod.wp.swissre.com/",
    GETTER_CLAIM: "https://o365externalcollaboration.azurewebsites.net/user_impersonation",
    SETTER_CLAIM: "https://sr-aad-prd-eu-w-as-o365extcoll.azurewebsites.net/user_impersonation",
    GETTER_API_ROOT: "https://office365-np-myexternalshares-read.api-np.swissre.com",
    SETTER_API_ROOT: "https://office365-np-myexternalshares-write.api-np.swissre.com"
}

const newProd = {
    TENANT_ID: "45597f60-6e37-4be7-acfb-4c9e23b261ea",
    APP_CLIENT_ID: "6e1ec4b1-21cc-493e-b355-ad4512fe73e9",
    REDIRECT_URI: "https://myexternalshares.wp.swissre.com/",
    GETTER_CLAIM: "https://o365externalcollaboration.azurewebsites.net/user_impersonation",
    SETTER_CLAIM: "https://sr-aad-prd-eu-w-as-o365extcoll.azurewebsites.net/user_impersonation",
    GETTER_API_ROOT: "https://office365-p-myexternalshares-read.api.swissre.com",
    SETTER_API_ROOT: "https://office365-p-myexternalshares-write.api.swissre.com"
}

let config = local

if (window.location.origin === "https://myexternalshares.azurewebsites.net") {
    config = dev
}

if (window.location.origin === "https://myexternalshares.swissredev.com") {
    config = devCustom
}

if (window.location.origin === 'https://o365externalcollaborationui.azurewebsites.net') {
    config = prod
}

if (window.location.origin === 'https://myexternalshares.swissre.com') {
    config = prodCustom
}

if (window.location.origin === 'https://o365externalcollaborationuinp.azurewebsites.net') {
    config = nonprod
}

if (window.location.origin === 'https://myexternalshares.swissrenonprod.com') {
    config = nonprodCustom
}

if (window.location.origin === 'https://myexternalshares-nonprod.wp.swissre.com') {
    config = nonprodProdTenant
}

if (window.location.origin === 'https://myexternalshares.wp.swissre.com') {
    config = newProd
}

module.exports = config
